import React, { useState, useEffect } from "react";
import FooterNavigation from "./FooterNavigation";
import axios from "axios";
import platform from "platform";

const Settings = ({ selectedDeviceId, setSelectedDeviceId }) => {
  const [devices, setDevices] = useState([]);
  const [singleTimer, setSingleTimer] = useState(3);
  const [seriesTimer, setSeriesTimer] = useState(4);
  const [mirrorCamera, setMirrorCamera] = useState(true); // Spiegelungszustand
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleString());
  const [showAlert, setShowAlert] = useState(false);
  const [latestImage, setLatestImage] = useState(null);
  const uploadFolder = "/cloud/";
  const browser = platform.name;
  const os = platform.os.family;
  const galleryUrl = `${window.location.origin}/gallery`;

  useEffect(() => {
    const getDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setDevices(videoDevices);
      if (!selectedDeviceId && videoDevices.length > 0) {
        setSelectedDeviceId(videoDevices[0].deviceId);
      }
    };

    getDevices();
    loadSettings();
    fetchLatestImage();

    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleString());
    }, 1000);

    return () => clearInterval(timer);
  }, [selectedDeviceId, setSelectedDeviceId]);

  const loadSettings = async () => {
    try {
      const response = await axios.get(`/settings.json?${Date.now()}`); // Cache-Busting
      const settings = response.data;
      setSingleTimer(settings.singleTimer || 3);
      setSeriesTimer(settings.seriesTimer || 4);
      setMirrorCamera(settings.mirrorCamera !== undefined ? settings.mirrorCamera : true);
      setSelectedDeviceId(settings.selectedDeviceId || selectedDeviceId);
    } catch (error) {
      console.error("Error loading settings:", error);
    }
  };

  const fetchLatestImage = async () => {
    try {
      const response = await axios.get(`/includes/list_images.php?${Date.now()}`);
      const images = response.data;
      if (images.length > 0) {
        setLatestImage(`/cloud/${images.reverse()[0]}?${Date.now()}`); // Cache-Busting
      }
    } catch (error) {
      console.error('Error fetching latest image:', error);
    }
  };

  const saveSettings = async () => {
    const settings = {
      singleTimer,
      seriesTimer,
      mirrorCamera,
      selectedDeviceId,
    };
    try {
      await axios.post('/includes/save_settings.php', settings); // URL zum Speichern der Settings
      setShowAlert(true); // Show success alert
      setTimeout(() => {
        setShowAlert(false);
        window.location.reload(); // Reload the page after showing the alert
      }, 3000); // Display alert for 3 seconds
    } catch (error) {
      console.error('Error saving settings:', error);
      alert('Error saving settings');
    }
  };

  const handleDeviceChange = (event) => {
    setSelectedDeviceId(event.target.value);
  };

  return (
    <main className="ifwebcam is-page-settings" style={{ backgroundImage: latestImage ? `url(${latestImage})` : 'none', backgroundSize: 'cover' }}>
      {showAlert && (
        <div className="ifwebcam--alert">
          Einstellungen wurden erfolgreich gespeichert.
        </div>
      )}
      <div className="ifwebcam--mode">Einstellungen</div>
      <table>
        <tbody>
          <tr>
            <td className="ifwebcam--settings-label">
              <label htmlFor="camera">Kamera auswählen: </label>
            </td>
            <td>
              <select
                id="camera"
                onChange={handleDeviceChange}
                value={selectedDeviceId}
              >
                {devices.map((device, index) => (
                  <option key={index} value={device.deviceId}>
                    {device.label || `Kamera ${index + 1}`}
                  </option>
                ))}
              </select>
            </td>
          </tr>
          <tr>
            <td className="ifwebcam--settings-label">
              <label htmlFor="singleTimer">Einzelaufnahme Timer (Sekunden): </label>
            </td>
            <td>
              <input
                type="number"
                id="singleTimer"
                value={singleTimer}
                onChange={(e) => setSingleTimer(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="ifwebcam--settings-label">
              <label htmlFor="seriesTimer">Serienaufnahme Timer (Sekunden): </label>
            </td>
            <td>
              <input
                type="number"
                id="seriesTimer"
                value={seriesTimer}
                onChange={(e) => setSeriesTimer(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td className="ifwebcam--settings-label">
              <label htmlFor="mirrorCamera">Kamerabild spiegeln: </label>
            </td>
            <td>
              <input
                type="checkbox"
                id="mirrorCamera"
                checked={mirrorCamera}
                onChange={(e) => setMirrorCamera(e.target.checked)}
              />
            </td>
          </tr>
          <tr>
            <td className="ifwebcam--settings-label">Upload-Ordner:</td>
            <td>
              <code>{uploadFolder}[image_current_time.jpg]</code>
            </td>
          </tr>
          <tr>
            <td className="ifwebcam--settings-label">Datum und Uhrzeit:</td>
            <td>{currentTime}</td>
          </tr>
          <tr>
            <td className="ifwebcam--settings-label">Browser:</td>
            <td>{browser}</td>
          </tr>
          <tr>
            <td className="ifwebcam--settings-label">Betriebssystem:</td>
            <td>{os}</td>
          </tr>
        </tbody>
      </table>
      <button className="saveSettings" onClick={saveSettings}>Einstellungen speichern</button>
      <FooterNavigation />
    </main>
  );
};

export default Settings;
