import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import FooterNavigation from "./FooterNavigation"; // Import FooterNavigation
import axios from "axios";

const Help = () => {
  const [backgroundImage, setBackgroundImage] = useState("");
  const contactEmail = "admin@photobox.if.digital";

  useEffect(() => {
    const loadLastImage = async () => {
      try {
        const response = await axios.get(`/includes/list_images.php?${Date.now()}`);
        const images = response.data;
        if (images.length > 0) {
          setBackgroundImage(`/cloud/${images[images.length - 1]}`);
        }
      } catch (error) {
        console.error("Error loading gallery images:", error);
      }
    };

    loadLastImage();
  }, []);

  return (
    <main className="ifwebcam is-page-help" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}>
      <div className="ifwebcam--mode">Hilfe und Information</div>

      <div className="is-page-help--inner">
        <div className="ifwebcam-columns">
          <div style={{ width: "80%" }} className="ifwebcam-column">
            <h2>Wie die App funktioniert</h2>
            <ol>
              <li>
                <strong>Foto aufnehmen:</strong>
                <ul>
                  <li>Gehen Sie zur <strong>Webcam-Seite</strong>.</li>
                  <li>Klicken Sie auf <strong>"Selbstauslöser starten"</strong>.</li>
                  <li>Der Countdown beginnt, und nach Ablauf wird das Foto automatisch aufgenommen.</li>
                </ul>
              </li>
              <li>
                <strong>Serienaufnahmen:</strong>
                <ul>
                  <li>Wechseln Sie auf der <strong>Webcam-Seite</strong> in den <strong>Serienaufnahme-Modus</strong>.</li>
                  <li>Klicken Sie auf den <strong>"Selbstauslöser starten"</strong>-Button.</li>
                  <li>Mehrere Fotos werden hintereinander mit kurzen Abständen aufgenommen.</li>
                </ul>
              </li>
              <li>
                <strong>Bilder in der Galerie anzeigen:</strong>
                <ul>
                  <li>Besuchen Sie die <strong>Galerie-Seite</strong>, um Ihre aufgenommenen Bilder anzusehen.</li>
                  <li>Die neuesten Bilder werden zuerst angezeigt.</li>
                </ul>
              </li>
              <li>
                <strong>Bilder herunterladen:</strong>
                <ul>
                  <li>In der <strong>Galerie-Seite</strong> können Sie Ihre Bilder herunterladen, indem Sie auf das gewünschte Bild klicken und den <strong>"Download"</strong>-Button betätigen.</li>
                </ul>
              </li>
              <li>
                <strong>Bilder auf andere Geräte übertragen:</strong>
                <ul>
                  <li>Gehen Sie zur <strong>QR-Seite</strong>.</li>
                  <li>Scannen Sie den angezeigten <strong>QR-Code</strong> mit der Kamera Ihres Smartphones.</li>
                  <li>Nach dem Scannen werden Sie zur <strong>Galerie-Seite</strong> weitergeleitet.</li>
                  <li>Hier können Sie die Bilder direkt auf Ihr Smartphone herunterladen, indem Sie auf das gewünschte Bild klicken und den <strong>"Download"</strong>-Button betätigen.</li>
                </ul>
              </li>
            </ol>
          </div>
          <div style={{ width: "20%" }} className="ifwebcam-column">
            <h2>Anmietung</h2>
            <QRCode value={`mailto:${contactEmail}`} size={256} />
            <p>
              Diese Software wurde von der IF.DIGITAL<sup>®</sup> GmbH entwickelt. Für Anfragen zur Anmietung der Photobox, senden Sie bitte eine E-Mail an{" "}
              <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            </p>
          </div>
        </div>
      </div>

      <FooterNavigation />
    </main>
  );
};

export default Help;
