import React from 'react';
import { NavLink } from 'react-router-dom';

const FooterNavigation = ({ setMode }) => {
  return (
    <nav className="ifwebcam--footer-navigation">
      <div className="ifwebcam--footer-navigation-col">
        <NavLink to="/help" activeClassName="active">
          <button className="ifwebcam--button-help">Info / Hilfe</button>
        </NavLink>
        <NavLink to="/settings" activeClassName="active">
          <button className="ifwebcam--button-settings">Einstellungen</button>
        </NavLink>
      </div>
      <div className="ifwebcam--footer-navigation-col">
        {setMode ? (
          <button className="ifwebcam--button-take-foto" onClick={() => setMode("capture")}>
            Fotomodus starten
          </button>
        ) : (
          <NavLink to="/" exact activeClassName="active">
            <button className="ifwebcam--button-take-foto is-style-back">Zurück zur Webcam</button>
          </NavLink>
        )}
      </div>
      <div className="ifwebcam--footer-navigation-col">
        <NavLink to="/gallery" activeClassName="active">
          <button className="ifwebcam--button-gallery">Galerie</button>
        </NavLink>
        <NavLink to="/qr" activeClassName="active">
          <button className="ifwebcam--button-download">Download via QR</button>
        </NavLink>
      </div>
    </nav>
  );
};

export default FooterNavigation;
