import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FooterNavigation from "./FooterNavigation"; // Korrekte Import-Pfad für FooterNavigation

const Gallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`/includes/list_images.php?${Date.now()}`); // Korrekte URL zur PHP-Datei mit Cache-Busting
        console.log('Images fetched:', response.data); // Debugging-Ausgabe
        setImages(response.data.reverse()); // Umkehren der Reihenfolge der Bilder
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();

    // Polling every 5 seconds to check for new images
    const intervalId = setInterval(fetchImages, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <main className="ifwebcam">
      <div className="ifwebcam--mode">Galerie</div>
      <div className="ifwebcam--image-grid">
        {images.length > 0 ? (
          images.map((image, index) => (
            <div key={index} className="image-item">
              <img
                src={`/cloud/${image}?${Date.now()}`}
                alt={`Capture ${index}`}
                loading="lazy" // Native Lazy Loading
              />
              <a className="image-item--download" href={`/cloud/${image}`} download></a>
            </div>
          ))
        ) : (
          <p>No images found.</p>
        )}
      </div>
      <FooterNavigation />
    </main>
  );
};

export default Gallery;
