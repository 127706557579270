import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import FooterNavigation from "./FooterNavigation";
import axios from "axios";

const QR = () => {
  const [latestImage, setLatestImage] = useState(null);
  const galleryUrl = `${window.location.origin}/gallery`;

  useEffect(() => {
    const fetchLatestImage = async () => {
      try {
        const response = await axios.get(`/includes/list_images.php?${Date.now()}`);
        const images = response.data;
        if (images.length > 0) {
          setLatestImage(`/cloud/${images.reverse()[0]}?${Date.now()}`); // Cache-Busting
        }
      } catch (error) {
        console.error('Error fetching latest image:', error);
      }
    };

    fetchLatestImage();
  }, []);

  return (
    <main className="ifwebcam is-page-qr-code" style={{ backgroundImage: `url(${latestImage})`, backgroundSize: 'cover' }}>
      <div className="ifwebcam--mode">Download via QR-Code</div>
      <div className="ifwebcam--download-via-qr-description">
        Scanne jetzt den QR Code und lade dir deine Bilder direkt auf dein
        Smartphone.
      </div>
      <QRCode
        className="ifwebcam--download-via-qr"
        value={galleryUrl}
        size={512}
      />
      <FooterNavigation />
    </main>
  );
};

export default QR;
