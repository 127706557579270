import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import { Link } from "react-router-dom";

const WebcamCapture = ({ selectedDeviceId }) => {
  const [capturing, setCapturing] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [lastImage, setLastImage] = useState(null);
  const [images, setImages] = useState([]);
  const [mode, setMode] = useState("view");
  const [captureMode, setCaptureMode] = useState("single");
  const [showAlert, setShowAlert] = useState(false);
  const [flash, setFlash] = useState(false);
  const [singleTimer, setSingleTimer] = useState(3);
  const [seriesTimer, setSeriesTimer] = useState(4);
  const [seriesCount, setSeriesCount] = useState(4); // Anzahl der Serienaufnahmen
  const [mirrorCamera, setMirrorCamera] = useState(true); // Spiegelungszustand
  const [galleryImages, setGalleryImages] = useState([]);
  const webcamRef = useRef(null);
  const defaultImageUrl = "../img/screensaver.jpg";
  const defaultImages = [
    "../img/screensaver.jpg",
    "../img/screensaver.jpg",
    "../img/screensaver.jpg",
    "../img/screensaver.jpg",
  ];

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  useEffect(() => {
    const loadSettings = async () => {
      try {
        const response = await axios.get(`/settings.json?${Date.now()}`); // Cache-Busting
        const settings = response.data;
        setSingleTimer(settings.singleTimer || 3);
        setSeriesTimer(settings.seriesTimer || 4);
        setSeriesCount(settings.seriesCount || 4); // Anzahl der Serienaufnahmen
        setMirrorCamera(
          settings.mirrorCamera !== undefined ? settings.mirrorCamera : true
        );
      } catch (error) {
        console.error("Error loading settings:", error);
      }
    };
    loadSettings();
    loadGalleryImages();
  }, []);

  const loadGalleryImages = async () => {
    try {
      const response = await axios.get(
        `/includes/list_images.php?${Date.now()}`
      ); // Cache-Busting
      const images = response.data;
      setGalleryImages(images.slice(-4)); // Load last 4 images from gallery
    } catch (error) {
      console.error("Error loading gallery images:", error);
    }
  };

  const handleCapture = async () => {
    setCapturing(true);
    let count = singleTimer;
    const interval = setInterval(() => {
      setCountdown(count);
      if (count === 0) {
        clearInterval(interval);
        setFlash(true);
        setTimeout(() => {
          const imageSrc = webcamRef.current.getScreenshot();
          setLastImage(imageSrc);
          uploadImage(imageSrc);
          setMode("view");
          setCapturing(false);
          setCountdown(0);
          setShowAlert(true);
          setFlash(false);
        }, 300);
      }
      count--;
    }, 1000);
  };

  const handleSeriesCapture = async () => {
    setCapturing(true);
    const newImages = [];
    let remainingCaptures = seriesCount; // Anzahl der Serienaufnahmen

    const captureImage = () => {
      if (remainingCaptures > 0) {
        let countdown = seriesTimer;
        const interval = setInterval(() => {
          setCountdown(countdown);
          if (countdown === 0) {
            clearInterval(interval);
            setFlash(true);
            setTimeout(() => {
              const imageSrc = webcamRef.current.getScreenshot();
              newImages.push(imageSrc);
              setImages([...newImages]);
              setLastImage(imageSrc); // Set the last image as the placeholder
              uploadImage(imageSrc);
              setFlash(false);
              remainingCaptures--;
              if (remainingCaptures > 0) {
                setTimeout(captureImage, 1000); // Delay before capturing the next image
              } else {
                setMode("view");
                setCapturing(false);
                setCountdown(0);
                setShowAlert(true);
              }
            }, 300); // Flash effect duration
          }
          countdown--;
        }, 1000);
      }
    };

    captureImage();
  };

  const uploadImage = async (imageSrc) => {
    const blob = await fetch(imageSrc).then((res) => res.blob());
    const formData = new FormData();
    formData.append("image", blob, `photo_${Date.now()}.jpg`);

    try {
      const response = await axios.post(
        "https://photobox.if.digital/includes/upload.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Image uploaded:", response.data);
      loadGalleryImages(); // Reload gallery images after upload
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const startCapture = () => {
    if (captureMode === "single") {
      handleCapture();
    } else if (captureMode === "series") {
      handleSeriesCapture();
    }
  };

  const getFallbackImages = () => {
    if (galleryImages.length > 0) {
      const fallbackImages = [...galleryImages];
      while (fallbackImages.length < 4) {
        fallbackImages.push(defaultImages[fallbackImages.length]);
      }
      return fallbackImages.slice(-4); // Return the last 4 images from the gallery
    } else {
      return defaultImages; // Return default images if gallery is empty
    }
  };

  return (
    <main className="ifwebcam">
      {flash && <div className="ifwebcam--flash"></div>}
      <div className="ifwebcam--mode">
        <input
          type="checkbox"
          className="toggle-switch-checkbox"
          id="toggleSwitch"
          checked={captureMode === "series"}
          onChange={() =>
            setCaptureMode(captureMode === "series" ? "single" : "series")
          }
        />
        <label className="toggle-switch-label" htmlFor="toggleSwitch">
          <span className="toggle-switch-inner" />
          <span className="toggle-switch-switch" />
        </label>
        <div className="toggle-labels">
          <span className={captureMode === "single" ? "active" : ""}>
            Einzelbild-Aufnahme
          </span>
          <span className={captureMode === "series" ? "active" : ""}>
            Serien-Aufnahme
          </span>
        </div>
      </div>
      {mode === "capture" ? (
        <>
          <figure className="ifwebcam--liveview">
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{ 
                deviceId: selectedDeviceId,
                width: 3840, // 4K Breite
                height: 2160 // 4K Höhe
              }}
              mirrored={mirrorCamera} // Spiegeln des Kamerabilds basierend auf den Einstellungen
            />
          </figure>
          <div className="ifwebcam--countdown">
            {capturing && countdown > 0 ? `${countdown}` : ""}
          </div>
          <nav className="ifwebcam--footer-navigation">
            <button
              className="ifwebcam--button-take-foto is-style-timer"
              onClick={startCapture}
              disabled={capturing}
            >
              {capturing ? `Lächeln in ${countdown}` : "Selbstauslöser starten"}
            </button>
          </nav>
        </>
      ) : (
        <>
          {captureMode === "single" && (
            <figure className="ifwebcam--preview-single">
              <img
                src={
                  lastImage ||
                  (galleryImages[0]
                    ? `/cloud/${galleryImages[0]}`
                    : defaultImageUrl)
                }
                alt="Last capture"
              />
              {showAlert && (
                <div className="ifwebcam--alert">
                  Das Bild wurde hochgeladen und im Ordner `/public/cloud/`
                  gespeichert.
                </div>
              )}
            </figure>
          )}
          {captureMode === "series" && (
            <figure className="ifwebcam--preview-image-grid">
              {getFallbackImages().map((img, index) => (
                <img
                  key={index}
                  src={img.startsWith("http") ? img : `/cloud/${img}`}
                  alt={`Capture ${index + 1}`}
                />
              ))}
              {showAlert && (
                <div className="ifwebcam--alert">
                  Die Bilder wurden hochgeladen und im Ordner `/public/cloud/`
                  gespeichert.
                </div>
              )}
            </figure>
          )}
          <nav className="ifwebcam--footer-navigation">
            <div className="ifwebcam--footer-navigation-col">
              <Link to="/help">
                <button className="ifwebcam--button-help">Info / Hilfe</button>
              </Link>
              <Link to="/settings">
                <button className="ifwebcam--button-settings">
                  Einstellungen
                </button>
              </Link>
            </div>
            <div className="ifwebcam--footer-navigation-col">
              <button
                className="ifwebcam--button-take-foto"
                onClick={() => setMode("capture")}
              >
                Fotomodus starten
              </button>
            </div>
            <div className="ifwebcam--footer-navigation-col">
              <Link to="/gallery">
                <button className="ifwebcam--button-gallery">Galerie</button>
              </Link>
              <Link to="/qr">
                <button className="ifwebcam--button-download">
                  Download via QR
                </button>
              </Link>
            </div>
          </nav>
        </>
      )}
    </main>
  );
};

export default WebcamCapture;
