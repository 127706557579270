import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import WebcamCapture from "./pages/WebcamCapture";
import Gallery from "./pages/Gallery";
import QR from "./pages/QR";
import Help from "./pages/Help";
import Settings from "./pages/Settings";
import FooterNavigation from "./pages/FooterNavigation";

const App = () => {
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    const checkOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    const intervalId = setInterval(checkOnlineStatus, 60000); // Check every minute

    // Initial check
    checkOnlineStatus();

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return (
    <Router>
      <div className="app">
        {!isOnline && (
          <div className="offline-alert">
            <p>Die App ist offline. Bitte überprüfen Sie Ihre Internetverbindung.</p>
            <button onClick={() => window.location.reload()}>Refresh</button>
          </div>
        )}
        <RoutesWithTransitions>
          <Route path="/" element={<WebcamCapture selectedDeviceId={selectedDeviceId} />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/qr" element={<QR />} />
          <Route path="/help" element={<Help />} />
          <Route path="/settings" element={<Settings selectedDeviceId={selectedDeviceId} setSelectedDeviceId={setSelectedDeviceId} />} />
        </RoutesWithTransitions>
      </div>
    </Router>
  );
};

const RoutesWithTransitions = ({ children }) => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <Routes location={location}>
          {children}
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default App;
